import {
  getDashbaordErrors,
  getDashboardDetails1,
  updateDashboardErrors,
} from "@/services/app/api";
import { days, months } from "@/services/constants";
import { formatCurrency, formatNumber } from "@/services/generalFunctions";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useAuth } from "@/components/Auth/AuthContext";
import { useThemeContext } from "@/components/Themes/ThemeContextProvider";
import snackbar from "@/services/app/snackbar";
import { handleLoading } from "@/store/collectionsSlice";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import SearchIcon from "@mui/icons-material/Search";
import { BarChart } from "@mui/x-charts/BarChart";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ErrorOrWarningIcon } from "@UI/IconPack";
import { showFormated } from "@UI/PercentageChane";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { formatTimestampToDate } from "../../../services/generalFunctions";
import { data3 } from "./data";

const DashboardData = () => {
  const [totalToken, setTotalToken] = useState(0);
  const [totalTokenPercentage, setTotalTokenPercentage] = useState(0);

  const [totalApiCalls, setTotalAPiCalls] = useState(0);
  const [totalApiCallsPercentage, setTotalAPiCallsPercentage] = useState(0);

  const [recentExecuted, setRecentExecuted] = useState([]);
  const [topExecuted, setTopExecuted] = useState([]);
  const [maxOutput, setMaxOutput] = useState([]);

  const [totalSpend, setTotalSpend] = useState(0);
  const [totalSpendPercentage, setTotalSpendPercentage] = useState(0);
  const { mode, colors } = useThemeContext();
  const [period, setPeriod] = useState("week");
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [series, setSeries] = useState([]);
  const [seriesData, setSeriesData] = useState([]);

  const [requestSeries, setRequestSeries] = useState([]);
  const [requestSeriesData, setRequestSeriesData] = useState([]);

  const [day7Series, setDay7Series] = useState([]);
  const [day7SeriesData, setDay7SeriesData] = useState([]);

  const [induvisualModel, setInduvisualModel] = useState([]);
  const [induvisualModelData, setInduvisualModelData] = useState([]);
  const [induvisualModelIntegrated, setInduvisualModelIntegrated] = useState(
    []
  );
  const [induvisualModelIntegratedData, setInduvisualModelIntegratedData] =
    useState([]);

  const [apiCallSeries, setApiCallSeries] = useState([]);
  const [apiCallSeriesData, setApiCallSeriesData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [lineGraphData, setLineGraphData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [openFilterData, setOpenFilterData] = useState(false);
  const [filterLineData, setFilterLineData] = useState([]);
  const [filterAdditionalData, setFilterAdditionalData] = useState([]);
  const { activeTeam } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchData = () => {
    const paramsWithPercentage = {
      time: period,
      percentage: true,
      state: 1,
    };

    const paramsWithPrevState = {
      time: period,
      percentage: true,
      state: 2,
    };

    const payloadWithPercentage = {
      team_id: activeTeam,
      params: paramsWithPercentage,
    };
    var payloadWithoutPercentage = {
      team_id: activeTeam,
      params: {
        time: period,
        state: 1,
      },
    };

    var dis = null;

    if (period == "week") {
      const now = dayjs();
      dis = {
        month: "short",
        day: "numeric",
      };
      // Get month start (1st day of the current month at 00:00:00)
      const monthStart = now.startOf("month").format("YYYY-MM-DDTHH:mm:ss");

      // Get month end (Last day of the current month at 23:59:59)
      const monthEnd = now.endOf("month").format("YYYY-MM-DDTHH:mm:ss");

      // Get today's end time (23:59:59 of the current day)
      const todayEnd = now.endOf("day").format("YYYY-MM-DDTHH:mm:ss");

      // Get the start of the day exactly 7 days back (00:00:00)
      const sevenDaysBackStart = now
        .subtract(7, "day")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");

      payloadWithoutPercentage = {
        team_id: activeTeam,
        params: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          start_date: sevenDaysBackStart,
          end_date: todayEnd,
          granularity: "day",
        },
      };
    }

    if (period == "year") {
      const now = dayjs();
      dis = {
        month: "short",
        day: "numeric",
        year: "numeric",
      };

      const currentDate = now.format("YYYY-MM-DDTHH:mm:ss");
      const oneYearBackDate = now
        .subtract(1, "year")
        .format("YYYY-MM-DDTHH:mm:ss");

      payloadWithoutPercentage = {
        team_id: activeTeam,
        params: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          start_date: oneYearBackDate,
          end_date: currentDate,
          granularity: "month",
        },
      };
    }

    if (period == "day") {
      const now = dayjs();
      dis = {
        weekday: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const last24HoursStart = now
        .subtract(24, "hours")
        .format("YYYY-MM-DDTHH:mm:ss");
      const endOfCurrentHour = now.endOf("hour").format("YYYY-MM-DDTHH:mm:ss");
      payloadWithoutPercentage = {
        team_id: activeTeam,
        params: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          start_date: last24HoursStart,
          end_date: endOfCurrentHour,
          granularity: "hour",
        },
      };
    }
    // alert(period);
    if (period == "month") {
      const now = dayjs();
      dis = "WeekDay";

      const start_day = now.endOf("day").format("YYYY-MM-DDTHH:mm:ss");

      const last30dayAgo = now
        .subtract(30, "day")
        .format("YYYY-MM-DDTHH:mm:ss");
      payloadWithoutPercentage = {
        team_id: activeTeam,
        params: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          start_date: last30dayAgo,
          end_date: start_day,
          granularity: "week",
        },
      };
    }

    const payloadWithPrevState = {
      team_id: activeTeam,
      params: paramsWithPrevState,
    };
    dispatch(handleLoading(true));
    setLoading(true);

    Promise.all([
      // getDashboardDetails(payloadWithPercentage),
      getDashboardDetails1(payloadWithoutPercentage),
      // getDashboardDetails(payloadWithPrevState),
    ])
      .then(([resWithoutPercentage]) => {
        // if (resWithPercentage?.items?.length > 0) {
        //   setApiData(resWithPercentage.items);
        // } else {
        //   setApiData([]);
        // }

        if (resWithoutPercentage?.items?.length > 0) {
          const result2 = processRequestSeries(resWithoutPercentage, dis);
          setDay7Series(result2["series"]);
          setDay7SeriesData(result2["data"]);
          var model_cost = modelExecuatationStats(resWithoutPercentage, dis);
          setInduvisualModel(model_cost["series"]);
          setInduvisualModelData(model_cost["data"], dis);

          var model_cost_integrated = modelExecuatationStatsIntegrated(
            resWithoutPercentage,
            dis
          );

          setInduvisualModelIntegrated(model_cost_integrated["series"]);
          setInduvisualModelIntegratedData(model_cost_integrated["data"], dis);
          var model_api_call = modelExecuatationStatsIntegratedApiCalls(
            resWithoutPercentage,
            dis
          );

          setApiCallSeries(model_api_call["series"]);
          setApiCallSeriesData(model_api_call["data"], dis);

          const result = processModelStats(resWithoutPercentage);

          setSeries(result["series"]);
          setSeriesData(result["data"]);
          calculate_level_data(resWithoutPercentage);
        } else {
          setLineGraphData([]);
        }
        // if (resWithPrevState?.items?.length > 0) {
        //   setAdditionalData(resWithPrevState.items);
        // } else {
        //   setAdditionalData([]);
        // }
      })

      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
        setLoading(false);
      });
  };
  const Label = ({ className = "", children }) => (
    <p className={`text-sm  text-primary  ${className}`}>{children}</p>
  );
  const Value = ({ className = "", children }) => (
    <p className={`text-sm text-primary  ${className}`}>{children}</p>
  );

  const fetchErrors = () => {
    getDashbaordErrors(activeTeam)
      .then((res) => {
        setErrors(res?.items);
      })
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      });
  };

  const defaultResult = { result: { input_tokens: 0, output_tokens: 0 } };

  const getMatchingItem = (lineGraphData, matchFn) =>
    lineGraphData.find(matchFn) || defaultResult;

  const generateXLabels = (length, formatFn) =>
    Array.from({ length }, (_, index) => formatFn(index));

  const getData = () => {
    const now = new Date();
    let XLabels = [];
    let today = new Date();

    switch (period) {
      case "year":
        XLabels = generateXLabels(12, (index) => {
          const monthTime = new Date();
          monthTime.setMonth(today.getMonth() - (11 - index));
          return months[monthTime.getMonth()];
        });

        const filledYearData = generateXLabels(12, (index) => {
          const monthTime = new Date();
          monthTime.setMonth(today.getMonth() - (11 - index));
          return getMatchingItem(lineGraphData, (item) => {
            const itemDate = new Date(item.timestamp);
            return (
              itemDate.getMonth() === monthTime.getMonth() &&
              itemDate.getFullYear() === monthTime.getFullYear()
            );
          });
        });

        return { data: filledYearData, XLabels };

      case "month":
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );

        const getWeekOfMonth = (date) => {
          const dayOfMonth = date.getDate();
          const startDayOfWeek = firstDayOfMonth.getDay();
          return Math.ceil((dayOfMonth + startDayOfWeek) / 7);
        };

        const currentWeek = getWeekOfMonth(today);

        XLabels = generateXLabels(currentWeek, (index) => `Week ${index + 1}`);

        const filledMonthData = generateXLabels(currentWeek, (index) => {
          const weekStart = new Date(firstDayOfMonth);
          weekStart.setDate(
            firstDayOfMonth.getDate() + index * 7 - weekStart.getDay()
          );

          const weekEnd = new Date(weekStart);
          weekEnd.setDate(weekStart.getDate() + 6);

          return getMatchingItem(lineGraphData, (item) => {
            const itemDate = new Date(item.timestamp);
            return itemDate >= weekStart && itemDate <= weekEnd;
          });
        });

        return { data: filledMonthData, XLabels };

      case "week":
        XLabels = generateXLabels(7, (index) => {
          const dayTime = new Date();
          dayTime.setDate(dayTime.getDate() - (6 - index));
          return days[dayTime.getDay()];
        });

        const filledWeekData = generateXLabels(7, (index) => {
          const dayTime = new Date();
          dayTime.setDate(dayTime.getDate() - (6 - index));
          return getMatchingItem(lineGraphData, (item) => {
            const itemDate = new Date(item.timestamp);
            return (
              itemDate.getDate() === dayTime.getDate() &&
              itemDate.getMonth() === dayTime.getMonth() &&
              itemDate.getFullYear() === dayTime.getFullYear()
            );
          });
        });

        return { data: filledWeekData, XLabels };

      case "day":
        XLabels = generateXLabels(24, (index) => {
          const hourTime = new Date();
          hourTime.setHours(hourTime.getHours() - (23 - index));
          let hour = hourTime.getHours();
          let period = hour >= 12 ? "PM" : "AM";
          hour = hour % 12 || 12;
          return `${hour}${period}`;
        });

        const filledData = generateXLabels(24, (index) => {
          const hourTime = new Date();
          hourTime.setHours(hourTime.getHours() - (23 - index));

          return getMatchingItem(lineGraphData, (item) => {
            const itemDate = new Date(item.timestamp);

            const currentItemHour = Math.floor(
              itemDate.getTime() / (1000 * 60 * 60)
            );
            const targetHour = Math.floor(
              hourTime.getTime() / (1000 * 60 * 60)
            );

            return currentItemHour === targetHour;
          });
        });

        return { data: filledData, XLabels };

      default:
        return { data: lineGraphData, XLabels: [] };
    }
  };

  const { data, XLabels } = getData();
  const InputTokens = data.map((item) =>
    item.result ? item.result.input_tokens : 0
  ) || [0];
  const OutputTokens = data.map((item) =>
    item.result ? item.result.output_tokens : 0
  ) || [0];

  const xLabels = XLabels.map((item) => item) || [0];

  const cost = apiData[0]?.result?.cost ?? 0;
  const checkValidFilterHandler = (periodValue) => {
    periodValue = periodValue.toLocaleLowerCase();
    if (periodValue == "day" || periodValue == "week") {
      const days = endDate.diff(startDate, "day");
      if (days > 300) {
        return {
          valid: false,
          message:
            "For the day interval, we currently support a maximum gap of 300 days. Please reduce the interval or change the gap filter.",
        };
      } else {
        return { valid: true, message: "" };
      }
    }
    if (periodValue == "minute") {
      const minutes = endDate.diff(startDate, "minute");
      if (minutes > 300) {
        return {
          valid: false,
          message: `For the minute interval, we currently support a maximum gap of 300 minute. Please reduce the interval or change the gap filter.`,
        };
      } else {
        return { valid: true, message: "" };
      }
    }
    if (periodValue == "hour") {
      const hr = endDate.diff(startDate, "hour");
      if (hr > 300) {
        return {
          valid: false,
          message: `For the hour interval, we currently support a maximum gap of 300 hour. Please reduce the interval or change the gap filter.`,
        };
      } else {
        return { valid: true, message: "" };
      }
    }
    return { valid: true, message: "" };
  };

  const clickHandler = (periodValue, stateValue) => {
    if (openFilterData) {
      var { valid, message } = checkValidFilterHandler(periodValue);
      if (valid == false) {
        snackbar.error({
          title: `${message}`,
        });
      } else {
        setPeriod(periodValue);
      }
    } else {
      setPeriod(periodValue);
    }
  };

  const handleDismiss = (errorId) => {
    dispatch(handleLoading(true));
    const data = {
      error_dismiss: true,
    };

    updateDashboardErrors({ error_id: errorId, data })
      .then((res) => {
        fetchErrors();
      })
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => dispatch(handleLoading(false)));
  };

  const convertDateToUTC = (date) => {
    if (!date) return null;

    return Math.floor(new Date(date).getTime() / 1000);
  };
  function processModelStats(response) {
    let series = [];
    let data = [];

    response.models_stats.forEach((rangeData, ind) => {
      data.push(rangeData.range);

      rangeData.model.forEach((model) => {
        let existingIndex = series.findIndex(
          (s) => s?.label === model?.model_name
        );
        if (existingIndex != -1) {
          series[existingIndex].data[ind] = model.avg_response_time;
        } else {
          series.push(
            JSON.parse(
              JSON.stringify({
                label: model.model_name,
                data: new Array(response.models_stats.length).fill(null),
                valueFormatter: (value) => (value ? `${value} sec.` : "-"),
              })
            )
          );
          series["valueFormatter"] = (value) => (value ? `${value} sec.` : "-");
          series[series.length - 1].data[ind] = model.avg_response_time;
        }
        // if (series.hasOwnProperty(ind)) {
        //   series[ind].data.push(model.avg_response_time);
        // } else {
        //   series[ind] = JSON.parse(
        //     JSON.stringify({
        //       label: model.model_name,
        //       data: [model.avg_response_time],
        //     })
        //   );
        // }
      });
    });

    return { series, data };
  }

  function getNextWeekInterval(startDate = new Date()) {
    const start = new Date(startDate);
    const end = new Date(start);
    end.setDate(start.getDate() + 6); // 7-day interval

    // Format output (e.g., "Mar 17 - Mar 23")
    const options = { month: "short", day: "numeric" };
    return `${start.toLocaleDateString(
      "en-US",
      options
    )} - ${end.toLocaleDateString("en-US", options)}`;
  }

  function processRequestSeries(response, day_dis_type = null) {
    let series = [];
    let data = [];

    if (day_dis_type == null) {
      day_dis_type = {
        month: "short",
        day: "numeric",
        // weekday: "long",
      };
    }
    series = [
      {
        data: new Array(
          response?.items?.length ? response.items.length : 0
        ).fill(null),
        stack: "Aee",
        showMark: true,
        color: mode === "dark" ? "#9F7AEB" : "#6938EF",
        label: "Output Tokens",
        // valueFormatter: (value) => (value ? `${value} $` : "0 $"),
      },
      {
        data: new Array(
          response?.items?.length ? response.items.length : 0
        ).fill(null),
        stack: "stack122",
        color: mode === "dark" ? "#1F2937" : "#E5E7EB",
        showMark: true,
        label: "Input Tokens",
      },
      {
        data: new Array(response.items.length ? response.items.length : 0).fill(
          null
        ),
        stack: "stack1",
        showMark: true,
        color: mode === "dark" ? "#9F7AEB" : "#6938EF",
        label: "Total Tokens",
      },
    ];

    // series={[
    //   {
    //     data: InputTokens,
    //     label: "InputTokens",
    //     color: mode === "dark" ? "#9F7AEB" : "#6938EF",
    //     stack: "tokens",
    //   },
    //   {
    //     data: OutputTokens,
    //     label: "OutputTokens",
    //     color: mode === "dark" ? "#1F2937" : "#E5E7EB",
    //     stack: "tokens",
    //   },
    // ]}
    if (response?.items.length <= 0) {
      series = [];
    }

    response.items.forEach((rangeData, ind) => {
      // data.push(
      //   new Date(rangeData?.timestamp).toLocaleDateString("en-US", day_dis_type)
      // );
      if (day_dis_type == "WeekDay") {
        data.push(getNextWeekInterval(new Date(rangeData?.timestamp)));
        // data.push(`${ind + 1} Week`);
      } else {
        data.push(
          new Date(rangeData?.timestamp).toLocaleDateString(
            "en-US",
            day_dis_type
          )
        );
      }

      if (rangeData?.result?.total_output_tokens) {
        series[0].data[ind] = rangeData?.result?.total_output_tokens;
      }
      if (rangeData?.result?.total_input_tokens) {
        series[1].data[ind] = rangeData?.result?.total_input_tokens;
      }
      if (rangeData?.result?.total_tokens) {
        series[2].data[ind] = rangeData?.result?.total_tokens;
      }
    });

    return { series, data };
  }

  function modelExecuatationStats(response, day_dis_type = null) {
    let series = [];
    let data = [];

    if (day_dis_type == null) {
      day_dis_type = {
        month: "short",
        day: "numeric",
        // weekday: "long",
      };
    }
    response.items.forEach((rangeData, ind) => {
      data.push(
        new Date(rangeData?.timestamp).toLocaleDateString("en-US", day_dis_type)
      );

      rangeData?.models?.forEach((model) => {
        let existingIndex = series.findIndex(
          (s) => s?.label === model?.version_type
        );
        if (existingIndex != -1) {
          series[existingIndex].data[ind] = model.avg_response_time;
        } else {
          series.push(
            JSON.parse(
              JSON.stringify({
                label: model.version_type,
                data: new Array(response.items.length).fill(null),
                valueFormatter: (value) => (value ? `${value} sec.` : "-"),
              })
            )
          );
          series[series.length - 1]["valueFormatter"] = (value) =>
            value ? `${value} sec.` : "-";
          series[series.length - 1].data[ind] = model.avg_response_time;
        }
        // if (series.hasOwnProperty(ind)) {
        //   series[ind].data.push(model.avg_response_time);
        // } else {
        //   series[ind] = JSON.parse(
        //     JSON.stringify({
        //       label: model.model_name,
        //       data: [model.avg_response_time],
        //     })
        //   );
        // }
      });
    });

    return { series, data };
  }

  function modelExecuatationStatsIntegrated(response, day_dis_type = null) {
    let series = [];
    let data = [];
    if (day_dis_type == null) {
      day_dis_type = {
        month: "short",
        day: "numeric",
        // weekday: "long",
      };
    }
    response.items.forEach((rangeData, ind) => {
      data.push(
        new Date(rangeData?.timestamp).toLocaleDateString("en-US", day_dis_type)
      );

      rangeData?.models?.forEach((model) => {
        let existingIndex = series.findIndex(
          (s) => s?.label === `${model.version_type}-Input Tokens`
        );
        if (existingIndex != -1) {
          series[existingIndex].data[ind] = model.total_input_tokens;
        } else {
          series.push(
            JSON.parse(
              JSON.stringify({
                label: `${model.version_type}-Input Tokens`,
                data: new Array(response.items.length).fill(null),
                stack: `${model.version_type}`,
              })
            )
          );
          // series[series.length - 1]["valueFormatter"] = (value) =>
          //   value ? `${value} sec.` : "-";
          series[series.length - 1].data[ind] = model.total_input_tokens;
        }

        let existingIndex1 = series.findIndex(
          (s) => s?.label === `${model.version_type}-Output Tokens`
        );
        if (existingIndex1 != -1) {
          series[existingIndex1].data[ind] = model.total_output_tokens;
        } else {
          series.push(
            JSON.parse(
              JSON.stringify({
                label: `${model.version_type}-Output Tokens`,
                data: new Array(response.items.length).fill(null),
                stack: `${model.version_type}`,
              })
            )
          );
          // series[series.length - 1]["valueFormatter"] = (value) =>
          //   value ? `${value} sec.` : "-";
          series[series.length - 1].data[ind] = model.total_output_tokens;
        }

        let existingIndex12 = series.findIndex(
          (s) => s?.label === `${model.version_type}-Cost`
        );
        if (existingIndex12 != -1) {
          series[existingIndex12].data[ind] = `${model.total_cost}`;
        } else {
          series.push(
            JSON.parse(
              JSON.stringify({
                label: `${model.version_type}-Cost`,
                data: new Array(response.items.length).fill(null),
                stack: `${model.version_type}`,
              })
            )
          );
          // series[series.length - 1]["valueFormatter"] = (value) =>
          //   value ? `${value} sec.` : "-";
          series[series.length - 1].data[ind] = `${model.total_cost}`;
        }

        let existingIndex13 = series.findIndex(
          (s) => s?.label === `${model.version_type}-Api Call`
        );
        if (existingIndex13 != -1) {
          series[existingIndex13].data[ind] = `${model.total_count}`;
        } else {
          series.push(
            JSON.parse(
              JSON.stringify({
                label: `${model.version_type}-Api Call`,
                data: new Array(response.items.length).fill(null),
                stack: `${model.version_type}`,
              })
            )
          );
          // series[series.length - 1]["valueFormatter"] = (value) =>
          //   value ? `${value} sec.` : "-";
          series[series.length - 1].data[ind] = `${model.total_count}`;
        }
      });
    });

    return { series, data };
  }

  function modelExecuatationStatsIntegratedApiCalls(
    response,
    day_dis_type = null
  ) {
    let series = [];
    let data = [];
    if (day_dis_type == null) {
      day_dis_type = {
        month: "short",
        day: "numeric",
        // weekday: "long",
      };
    }
    response.items.forEach((rangeData, ind) => {
      data.push(
        new Date(rangeData?.timestamp).toLocaleDateString("en-US", day_dis_type)
      );

      rangeData?.models?.forEach((model) => {
        let existingIndex13 = series.findIndex(
          (s) => s?.label === `${model.version_type}-Api Call`
        );
        if (existingIndex13 != -1) {
          series[existingIndex13].data[ind] = `${model.total_count}`;
        } else {
          series.push(
            JSON.parse(
              JSON.stringify({
                label: `${model.version_type}-Api Call`,
                data: new Array(response.items.length).fill(null),
                stack: `${model.version_type}`,
              })
            )
          );
          // series[series.length - 1]["valueFormatter"] = (value) =>
          //   value ? `${value} sec.` : "-";
          series[series.length - 1].data[ind] = `${model.total_count}`;
        }
      });
    });

    return { series, data };
  }
  function forLineChart(response, day_dis_type = null) {
    let series = [];
    let data = [];

    if (day_dis_type == null) {
      day_dis_type = {
        month: "short",
        day: "numeric",
        // weekday: "long",
      };
    }
    day_dis_type = {
      weekday: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    series = [
      {
        id: "InputTokens",
        data: new Array(
          response?.items?.length ? response.items.length : 0
        ).fill(null),
        label: "InputTokens",
        type: "line",

        area: true,
        // stack: "total",

        showMark: false,
        color: mode === "dark" ? "#9B8AFB" : "#6938EF",
      },
      {
        id: "OutputTokens",
        data: new Array(
          response?.items?.length ? response.items.length : 0
        ).fill(null),
        label: "OutputTokens",
        type: "line",

        area: true,
        // stack: "total",

        showMark: false,
        color: mode === "dark" ? "#374151" : "#374151",
      },
      {
        id: "Total",
        data: new Array(
          response?.items?.length ? response.items.length : 0
        ).fill(null),
        label: "Total",
        type: "line",

        area: true,
        // stack: "total",

        showMark: false,
        color: mode === "dark" ? "#374151" : "#374151",
      },
    ];
    var series1 = [
      {
        data: new Array(
          response?.items?.length ? response.items.length : 0
        ).fill(null),
        stack: "Aee",
        showMark: true,
        color: mode === "dark" ? "#9F7AEB" : "#6938EF",
        label: "Total Cost",
        valueFormatter: (value) => (value ? `${value} $` : "0 $"), // Custom bar label
      },
      {
        data: new Array(
          response?.items?.length ? response.items.length : 0
        ).fill(null),
        stack: "stack122",
        color: mode === "dark" ? "#1F2937" : "#E5E7EB",
        showMark: true,
        label: "Input Tokens",
      },
      {
        data: new Array(response.items.length ? response.items.length : 0).fill(
          null
        ),
        stack: "stack1",
        showMark: true,
        color: mode === "dark" ? "#9F7AEB" : "#6938EF",
        label: "Output Tokens",
      },
    ];

    // series={[
    //   {
    //     data: InputTokens,
    //     label: "InputTokens",
    //     color: mode === "dark" ? "#9F7AEB" : "#6938EF",
    //     stack: "tokens",
    //   },
    //   {
    //     data: OutputTokens,
    //     label: "OutputTokens",
    //     color: mode === "dark" ? "#1F2937" : "#E5E7EB",
    //     stack: "tokens",
    //   },
    // ]}
    if (response?.items.length <= 0) {
      series = [];
    }

    response.items.forEach((rangeData, ind) => {
      // data.push(
      //   new Date(rangeData?.timestamp).toLocaleDateString("en-US", day_dis_type)
      // );
      if (day_dis_type == "WeekDay") {
        data.push(getNextWeekInterval(new Date(rangeData?.timestamp)));
        // data.push(`${ind + 1} Week`);
      } else {
        data.push(
          new Date(rangeData?.timestamp).toLocaleDateString(
            "en-US",
            day_dis_type
          )
        );
      }

      // if (rangeData?.result?.total_cost) {
      //   series[0].data[ind] = rangeData?.result?.total_cost;
      // }
      if (rangeData?.result?.total_input_tokens) {
        series[0].data[ind] = rangeData?.result?.total_input_tokens;
      }
      if (rangeData?.result?.total_output_tokens) {
        series[1].data[ind] = rangeData?.result?.total_output_tokens;
      }
      if (rangeData?.result?.total_tokens) {
        series[2].data[ind] = rangeData?.result?.total_tokens;
      }
    });

    return { series, data };
  }

  const handleFilterClick = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        snackbar.error({
          title: "Start date cannot be greater than end date.",
        });
        return;
      }
      var { valid, message } = checkValidFilterHandler(period);
      if (valid == false) {
        snackbar.error({
          title: `${message}`,
        });

        return;
      }

      setOpenFilterData(true);
      dispatch(handleLoading(true));
      setLoading(true);

      const paramsWithFilter = {
        time: period,
        start_date: convertDateToUTC(startDate),
        end_date: convertDateToUTC(endDate),
      };

      const paramsWithPercent = {
        time: period,
        start_date: convertDateToUTC(startDate),
        end_date: convertDateToUTC(endDate),
        percentage: true,
      };

      const payloadWithFilter = {
        team_id: activeTeam,
        params: paramsWithFilter,
      };

      const payloadWithPercent = {
        team_id: activeTeam,
        params: paramsWithPercent,
      };
      const modelGroupBy = {
        team_id: activeTeam,
        params: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          start_date: dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss"),
          end_date: dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss"),
          granularity: period,
        },
      };
      var dis = null;

      if (period == "week") {
        dis = {
          month: "short",
          day: "numeric",
        };
      }

      if (period == "year") {
        dis = {
          month: "short",
          day: "numeric",
          year: "numeric",
        };
      }

      if (period == "day") {
        dis = {
          weekday: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
      }

      if (period == "minute") {
        dis = {
          weekday: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
      }
      if (period == "hour") {
        dis = {
          weekday: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
      }
      // alert(period);
      if (period == "month") {
        dis = "WeekDay";
      }
      Promise.all([
        // getDashboardDetails(payloadWithFilter),
        // getDashboardDetails(payloadWithPercent),
        getDashboardDetails1(modelGroupBy),
      ])
        .then(([groupBy]) => {
          // setFilterLineData(filterRes?.items);
          // setFilterAdditionalData(percentRes.items);

          const result = processModelStats(groupBy);

          setSeries(result["series"]);
          setSeriesData(result["data"]);
          if (groupBy.items) {
            calculate_level_data(groupBy);
            const result2 = forLineChart(groupBy);
            setRequestSeries(result2["series"]);
            setRequestSeriesData(result2["data"]);

            const result212 = processRequestSeries(groupBy, dis);
            setDay7Series(result212["series"]);
            setDay7SeriesData(result212["data"]);
            var model_cost = modelExecuatationStats(groupBy, dis);
            setInduvisualModel(model_cost["series"]);
            setInduvisualModelData(model_cost["data"], dis);

            var model_cost_integrated = modelExecuatationStatsIntegrated(
              groupBy,
              dis
            );

            setInduvisualModelIntegrated(model_cost_integrated["series"]);
            setInduvisualModelIntegratedData(
              model_cost_integrated["data"],
              dis
            );
            var model_api_call = modelExecuatationStatsIntegratedApiCalls(
              groupBy,
              dis
            );

            setApiCallSeries(model_api_call["series"]);
            setApiCallSeriesData(model_api_call["data"], dis);
            calculate_level_data(groupBy);
          } else {
            setRequestSeries([]);
            setRequestSeriesData([]);
            setDay7Series([]);
            setDay7SeriesData([]);

            setInduvisualModel([]);
            setInduvisualModelData([]);

            setInduvisualModelIntegrated([]);
            setInduvisualModelIntegratedData([]);

            setApiCallSeries([]);
            setApiCallSeriesData([]);
          }

          // setSeries([]);
          // setSeriesData([]);
        })
        .catch((error) => {
          snackbar.error({
            title: `${error.error && error.error.toString()}`,
          });
        })
        .finally(() => {
          dispatch(handleLoading(false));
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchErrors();

    if (openFilterData) {
      handleFilterClick();
    } else {
      fetchData();
    }
  }, [period, activeTeam, openFilterData]);

  useEffect(() => {
    if (openFilterData && startDate && endDate) {
      handleFilterClick();
    }
  }, [openFilterData]);

  const InputLineTokens =
    filterLineData.length === 1
      ? [0, ...filterLineData.map((item) => item?.result?.input_tokens ?? 0)]
      : filterLineData.map((item) => item?.result?.input_tokens ?? 0);

  const OutputputLineTokens =
    filterLineData.length === 1
      ? [0, ...filterLineData.map((item) => item?.result?.output_tokens ?? 0)]
      : filterLineData.map((item) => item?.result?.output_tokens ?? 0);

  const xlabels =
    filterLineData.length === 1
      ? [
          "",
          ...filterLineData.map((item) =>
            item
              ? new Date(item?.timestamp).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })
              : 0
          ),
        ]
      : filterLineData.map((item) =>
          item
            ? new Date(item?.timestamp).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })
            : 0
        );

  const calculate_level_data = (res) => {
    setTotalToken(
      res?.total_data?.total_tokens_current
        ? res?.total_data?.total_tokens_current
        : 0
    );
    setTotalTokenPercentage(
      res?.total_data?.total_tokens_percentage_change
        ? res?.total_data?.total_tokens_percentage_change
        : 0
    );

    setTotalAPiCalls(
      res?.total_data?.total_count_current
        ? res?.total_data?.total_count_current
        : 0
    );
    setTotalAPiCallsPercentage(
      res?.total_data?.total_count_percentage_change
        ? res?.total_data?.total_count_percentage_change
        : 0
    );

    setTotalSpend(
      res?.total_data?.total_cost_current
        ? res?.total_data?.total_cost_current
        : 0
    );
    setTotalSpendPercentage(
      res?.total_data?.total_cost_percentage_change
        ? res?.total_data?.total_cost_percentage_change
        : 0
    );
    setRecentExecuted(res?.recent_executed ? res?.recent_executed : []);
    setMaxOutput(res?.max_output ? res?.max_output : []);
    setTopExecuted(res?.top_executed ? res?.top_executed : []);
  };

  return (
    <main className="">
      <div className="flex justify-between items-start px-[6%] md:space-x-4 w-full mt-[4%]">
        <h1 className="display-md-semibold text-primary">Dashboard</h1>

        <div className="flex gap-[12px] items-center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="truncate">
              <div className="flex space-x-1">
                {/* <DatePicker
                  value={startDate}
                  format="LL"
                  // maxDate={dayjs()}
                  onChange={(newValue) => setStartDate(newValue)}
                  slotProps={{ textField: { size: "small" } }}
                />

                <DatePicker
                  value={endDate}
                  format="LL"
                  onChange={(newValue) => setEndDate(newValue)}
                  minDate={startDate}
                  // maxDate={dayjs()}
                  slotProps={{ textField: { size: "small" } }}
                /> */}
                <DateTimePicker
                  value={startDate}
                  // onAccept={(newValue) => setEndDate(newValue)}
                  onChange={(newValue) => setStartDate(newValue)}
                  // minDate={startDate}
                  ampm={true} // Set to true for 12-hour format
                  format="LLL" // Format includes date and time
                  slotProps={{
                    textField: { size: "small" },
                    popper: { closeOnSelect: false },
                    dialog: { disableCloseOnSelect: true }, // Keeps it open until "OK" is clicked
                  }}
                />
                <DateTimePicker
                  value={endDate}
                  onAccept={(newValue) => setEndDate(newValue)}
                  onChange={(newValue) => setEndDate(newValue)}
                  minDate={startDate}
                  ampm={true} // Set to true for 12-hour format
                  format="LLL" // Format includes date and time
                  slotProps={{
                    textField: { size: "small" },
                    popper: { closeOnSelect: false },
                    dialog: { disableCloseOnSelect: true }, // Keeps it open until "OK" is clicked
                  }}
                />
              </div>
            </div>
          </LocalizationProvider>

          {!openFilterData && (
            <div
              onClick={() => {
                var { valid, message } = checkValidFilterHandler(period);
                if (valid == false) {
                  snackbar.error({
                    title: `${message}`,
                  });
                } else {
                  handleFilterClick();
                }
              }}
              className=" cursor-pointer max-w-[99px] max-h-[44px] rounded-md border border-white py-[6px] px-[14px] flex items-center space-x-1"
            >
              <FilterListIcon />
              <div className="max-w-[185px] max-h-[20px] ">
                <h2 className="text-sm-medium text-primary">Filter</h2>
              </div>
            </div>
          )}
          {openFilterData && (
            <div className="flex gap-2 items-center">
              <div
                onClick={() => {
                  handleFilterClick();
                }}
                className=" cursor-pointer max-w-[99px] max-h-[44px] rounded-md border border-white py-[6px] px-[14px] flex items-center space-x-1"
              >
                <SearchIcon />
                <div className="max-w-[185px] max-h-[20px] ">
                  <h2 className="text-sm-medium text-primary">Apply</h2>
                </div>
              </div>

              <div
                onClick={() => setOpenFilterData(false)}
                className=" cursor-pointer max-w-[99px] max-h-[44px] rounded-md border border-white py-[6px] px-[14px] flex items-center space-x-1"
              >
                <FilterListOffIcon />
                <div className="max-w-[185px] max-h-[20px] ">
                  <h2 className="text-sm-medium text-primary">Close</h2>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex px-[6%]  mt-[2%] gap-6">
        <div className="w-3/5">
          <div className="grid grid-cols-3 gap-4">
            <div className="w-full p-4 rounded-xl border border-primary">
              <div className="flex justify-between">
                <h2 className="text-[1rem] text-tertiary">Tokens Consumed</h2>
              </div>
              <div className="flex justify-between mt-[1em] items-center space-x-2  overflow-auto">
                <h2 className="text-lg-semibold text-primary">
                  {formatNumber(totalToken)}
                </h2>
                <div>{showFormated(totalTokenPercentage)}</div>
              </div>
            </div>

            <div className="w-full p-4 rounded-xl border border-primary">
              <div className="flex justify-between">
                <h2 className="text-[1rem] text-tertiary">
                  Tokens Invocations
                </h2>
              </div>
              <div className="flex justify-between mt-[1em] items-center space-x-2  overflow-auto">
                <h2 className="text-lg-semibold text-primary">
                  {formatNumber(totalApiCalls)}
                </h2>
                <div>{showFormated(totalApiCallsPercentage)}</div>
              </div>
            </div>

            <div className="w-full p-4 rounded-xl border border-primary">
              <div className="flex justify-between">
                <h2 className="text-[1rem] text-tertiary">Total Spend</h2>
              </div>
              <div className="flex justify-between mt-[1em] items-center space-x-2  overflow-auto">
                <h2 className="text-lg-semibold text-primary">
                  {formatCurrency(totalSpend)}
                </h2>
                <div>{showFormated(totalSpendPercentage)}</div>
              </div>
            </div>
          </div>

          <div className="w-full mt-[2%] border   border-secondary p-2 rounded-xl shadow-sm overflow-hidden">
            <h2 className="text-lg-semibold text-primary mb-[2%] pt-2 px-4">
              Tokens Consumed
            </h2>
            <div>
              <div className="flex justify-start  mb-[2%] space-x-4 w-full">
                {(openFilterData
                  ? ["Year", "Month", "Week", "Day", "Hour", "Minute"]
                  : ["year", "month", "week", "day"]
                ).map((p) => (
                  <button
                    key={p}
                    className={` px-4  py-2 text-[0.8rem] font-semibold rounded-md text-secondary transition-colors duration-200 ${
                      period === p.toLocaleLowerCase()
                        ? "bg-secondary"
                        : "bg-transparent"
                    }`}
                    onClick={() => clickHandler(p.toLocaleLowerCase())}
                  >
                    {!openFilterData
                      ? p === "year"
                        ? "Last 12 Months"
                        : p === "month"
                        ? "Last 1 Month"
                        : p === "week"
                        ? "Last 7 Days"
                        : "Last 24 Hours"
                      : p === "qmonth"
                      ? " Weekly data"
                      : p === "qweek"
                      ? " Daily dataq"
                      : `Group by ${p}`}
                  </button>
                ))}
              </div>
              {openFilterData && (
                <>
                  {loading ? (
                    <h1></h1>
                  ) : requestSeries.length > 0 ? (
                    <div className="flex justify-center items-center ">
                      {/* <LineChart
                        width={600}
                        height={330}
                        sx={{
                          "& .MuiAreaElement-series-OutputTokens": {
                            fill: "url('#grayGradient')",
                          },
                          "& .MuiAreaElement-series-InputTokens": {
                            fill: "url('#myGradient')",
                          },
                          "& .MuiChartsAxis-tick": {
                            strokeWidth: "0",
                          },

                          "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                            strokeWidth: "0",
                            fill: colors?.tertiary,
                          },

                          "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                            strokeWidth: "0",
                            fill: colors?.tertiary,
                          },

                          "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                            fill: colors?.tertiary,
                            strokeWidth: 0,
                          },

                          "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                            fill: colors?.tertiary,
                            strokeWidth: 0,
                          },
                        }}
                        series={requestSeries}
                        slotProps={{
                          legend: {
                            hidden: true,
                          },
                        }}
                        xAxis={[
                          { scaleType: "point", data: requestSeriesData },
                        ]}
                      >
                        <defs>
                          <linearGradient
                            id="myGradient"
                            gradientTransform="rotate(90)"
                          >
                            <stop
                              offset="0%"
                              style={{ stopColor: "rgba(155,138,251,1)" }}
                            />
                            <stop
                              offset="90%"
                              style={{
                                stopColor:
                                  mode === "dark"
                                    ? "rgba(0,0,0,0.1)"
                                    : "rgba(255,255,255,0.2)",
                              }}
                            />
                            <stop
                              offset="100%"
                              style={{
                                stopColor:
                                  mode === "dark"
                                    ? "rgba(0,0,0,0)"
                                    : "rgba(255,255,255,0)",
                              }}
                            />
                          </linearGradient>
                        </defs>
                        <defs>
                          <linearGradient
                            id="grayGradient"
                            gradientTransform="rotate(90)"
                          >
                            <stop
                              offset="0%"
                              style={{ stopColor: "rgba(55,65,81,1)" }}
                            />
                            <stop
                              offset="90%"
                              style={{
                                stopColor:
                                  mode === "dark"
                                    ? "rgba(0,0,0,0.1)"
                                    : "rgba(255,255,255,0.2)",
                              }}
                            />
                            <stop
                              offset="100%"
                              style={{
                                stopColor:
                                  mode === "dark"
                                    ? "rgba(0,0,0,0)"
                                    : "rgba(255,255,255,0)",
                              }}
                            />
                          </linearGradient>
                        </defs>
                      </LineChart> */}

                      <BarChart
                        width={600}
                        height={330}
                        sx={{
                          "& .MuiChartsAxis-tick": {
                            strokeWidth: "0",
                          },

                          "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                            strokeWidth: "0",
                            fill: colors?.tertiary,
                          },

                          "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                            strokeWidth: "0",
                            fill: colors?.tertiary,
                          },

                          "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                            fill: colors?.tertiary,
                            strokeWidth: 0,
                          },

                          "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                            fill: colors?.tertiary,
                            strokeWidth: 0,
                          },
                        }}
                        borderRadius={32}
                        // series={[
                        //   {
                        //     data: InputTokens,
                        //     label: "InputTokens",
                        //     color: mode === "dark" ? "#9F7AEB" : "#6938EF",
                        //     stack: "tokens",
                        //   },
                        //   {
                        //     data: OutputTokens,
                        //     label: "OutputTokens",
                        //     color: mode === "dark" ? "#1F2937" : "#E5E7EB",
                        //     stack: "tokens",
                        //   },
                        // ]}
                        series={day7Series}
                        slotProps={{
                          legend: { hidden: true },
                        }}
                        leftAxis={null}
                        xAxis={[{ data: day7SeriesData, scaleType: "band" }]}
                      />
                    </div>
                  ) : (
                    <div className="text-center text-primary m-auto h-40 flex items-center w-80 justify-center">
                      <div>No data available</div>
                    </div>
                  )}
                </>
              )}

              {!openFilterData && (
                <>
                  {loading ? (
                    <h1></h1>
                  ) : true ? (
                    <div className="relative overflow-auto flex gap-4 justify-between">
                      <BarChart
                        width={600}
                        height={330}
                        sx={{
                          "& .MuiChartsAxis-tick": {
                            strokeWidth: "0",
                          },

                          "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                            strokeWidth: "0",
                            fill: colors?.tertiary,
                          },

                          "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                            strokeWidth: "0",
                            fill: colors?.tertiary,
                          },

                          "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                            fill: colors?.tertiary,
                            strokeWidth: 0,
                          },

                          "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                            fill: colors?.tertiary,
                            strokeWidth: 0,
                          },
                        }}
                        borderRadius={32}
                        // series={[
                        //   {
                        //     data: InputTokens,
                        //     label: "InputTokens",
                        //     color: mode === "dark" ? "#9F7AEB" : "#6938EF",
                        //     stack: "tokens",
                        //   },
                        //   {
                        //     data: OutputTokens,
                        //     label: "OutputTokens",
                        //     color: mode === "dark" ? "#1F2937" : "#E5E7EB",
                        //     stack: "tokens",
                        //   },
                        // ]}
                        series={day7Series}
                        slotProps={{
                          legend: { hidden: true },
                        }}
                        leftAxis={null}
                        xAxis={[{ data: day7SeriesData, scaleType: "band" }]}
                      />
                    </div>
                  ) : (
                    <div className="text-center text-primary m-auto h-40 flex items-center w-80 justify-center">
                      <div>No data available</div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="w-2/5 flex flex-col  gap-2">
          {data3.map((item, index) => (
            <Link
              key={index}
              to={item.to}
              className="w-full h-[88px] p-[20px] rounded-xl border border-primary bg-primary flex items-center"
            >
              <div className="flex items-center space-x-4 w-full">
                <div className="h-[48px] w-[48px] rounded-lg border border-primary flex items-center justify-center">
                  <img
                    src={item.icon}
                    alt=""
                    className="object-contain h-[28px] w-[28px]"
                  />
                </div>
                <div>
                  <h1 className="text-primary text-md">{item.title}</h1>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div className="  mt-[2%] mx-[6%] ">
        <div>
          <div className="grid grid-cols-2 gap-6">
            <div className="border border-secondary rounded-xl">
              <h2 className="text-lg-semibold text-primary  mt-8 justify-self-center">
                1. Models Performance Over Tokens (Tokens vs. Avg. Execution
                Time)
              </h2>

              <div className="flex justify-center items-center ">
                <BarChart
                  xAxis={[
                    {
                      scaleType: "band",
                      data: seriesData?.length ? seriesData : [],
                    },
                  ]}
                  series={series?.length ? series : []}
                  width={600}
                  height={330}
                  slotProps={{
                    legend: {
                      hidden: true,
                    },
                  }}
                />
              </div>
            </div>

            <div className="border border-secondary rounded-xl">
              <div className="text-lg-semibold text-primary  mt-8 flex justify-self-center">
                2. Date Vs model Avg.Response time
              </div>
              <div className="flex justify-center items-center ">
                <BarChart
                  xAxis={[
                    {
                      scaleType: "band",
                      data: induvisualModelData?.length
                        ? induvisualModelData
                        : [],
                      label: "Date/Time ",
                    },
                  ]}
                  yAxis={[
                    {
                      label: "Time (sec)",
                    },
                  ]}
                  series={induvisualModel?.length ? induvisualModel : []}
                  width={600}
                  height={330}
                  slotProps={{
                    legend: {
                      hidden: true,
                    },
                  }}
                />
              </div>
            </div>

            <div className="border border-secondary rounded-xl">
              <div className="text-lg-semibold text-primary  mt-8 flex justify-self-center">
                3. Date Vs Tokens
              </div>
              <div className="flex justify-center items-center ">
                <BarChart
                  xAxis={[
                    {
                      scaleType: "band",
                      data: induvisualModelIntegratedData?.length
                        ? induvisualModelIntegratedData
                        : [],
                      label: "Date/Time ",
                    },
                  ]}
                  // yAxis={[
                  //   {
                  //     label: "Time (sec)",
                  //   },
                  // ]}
                  series={
                    induvisualModelIntegrated?.length
                      ? induvisualModelIntegrated
                      : []
                  }
                  width={600}
                  height={330}
                  slotProps={{
                    legend: {
                      hidden: true,
                    },
                  }}
                />
              </div>
            </div>

            <div className="border border-secondary rounded-xl">
              <div className="text-lg-semibold text-primary  mt-8 flex justify-self-center">
                4. Date Vs Api Calls
              </div>
              <div className="flex justify-center items-center ">
                <BarChart
                  xAxis={[
                    {
                      scaleType: "band",
                      data: apiCallSeriesData?.length ? apiCallSeriesData : [],
                      label: "Date/Time ",
                    },
                  ]}
                  // yAxis={[
                  //   {
                  //     label: "Time (sec)",
                  //   },
                  // ]}
                  series={apiCallSeries?.length ? apiCallSeries : []}
                  width={600}
                  height={330}
                  slotProps={{
                    legend: {
                      hidden: true,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* 
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: requestSeriesData?.length ? requestSeriesData : ["ddddd"],
            },
          ]}
          series={requestSeries?.length ? requestSeries : []}
          width={800}
          height={500}
        /> */}
      </div>
      {[
        { data: recentExecuted, text: "Recently Executed Prompts" },
        { data: topExecuted, text: "Frequently Used Prompts" },
        { data: maxOutput, text: "Prompt with high output." },
      ].map((item, indx) => {
        return item?.data?.length ? (
          <div className="  mt-[2%] mx-[6%] border border-secondary rounded-xl ">
            <h2 className="text-lg-semibold text-primary  mt-8 p-4  text-center">
              {indx + 1}.{item.text}
            </h2>

            <div className="flex justify-center">
              {indx == 1 ? (
                <div>
                  {item?.data?.map((item, index) => {
                    return (
                      <div className="flex gap-24   border-b-2 p-4 border-secondary overflow-auto ">
                        <div className="w-80">
                          <Label>{index + 1} ) Prompts Name </Label>
                          <Value>
                            {item?.prompt_name
                              ? item?.prompt_name
                              : item?.prompt_id}
                          </Value>
                        </div>
                        <div className="w-80">
                          <Label>Collection Name </Label>
                          <Value>
                            {item?.collection_name
                              ? item?.collection_name
                              : item?.collection_name}
                          </Value>
                        </div>

                        <div>
                          <Label>Usage Frequency</Label>
                          <div className="flex items-end gap-1">
                            <Value>{item?.usage_frequency || "0"}</Value>{" "}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>
                  {item?.data?.map((item, index) => {
                    return (
                      <div className="flex gap-24   border-b-2 p-4 border-secondary overflow-auto ">
                        <div className="w-80">
                          <Label>{index + 1} ) Prompts Name </Label>
                          <Value>
                            {item?.prompt_name
                              ? item?.prompt_name
                              : item?.prompt_id}
                          </Value>
                        </div>
                        <div>
                          <Label>Run at</Label>
                          <Value>
                            {formatTimestampToDate(
                              item?.created_at,
                              "MMMM DD, YYYY hh:mm:ss A"
                            )}
                          </Value>
                        </div>
                        <div>
                          <Label>Tokens consumed</Label>
                          <div className="flex items-end gap-1">
                            <Value>{formatNumber(item?.tokens || 0)}</Value>{" "}
                            <span className="text-base ">
                              {(item?.request_cost &&
                                formatCurrency(item?.request_cost)) ||
                                ""}
                            </span>
                          </div>
                        </div>
                        <div>
                          <Label>Response time</Label>
                          <div className="flex items-end gap-1">
                            <Value>{item?.response_time || "0"}</Value>{" "}
                            <span className="text-base ">Sec</span>
                          </div>
                        </div>
                        <div>
                          <Label>Model</Label>
                          <div className="flex gap-2">
                            <Value>{item?.model_type}</Value>
                          </div>
                        </div>
                        {/* <div>
                    <Label>Model</Label>
                    <div className="flex gap-2">
                      <Value>{item?.response}</Value>
                    </div>
                  </div> */}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        ) : null;
      })}

      <div className=" px-[6%]  mt-[2%] mx-[6%] border border-secondary rounded-xl">
        <div className="w-full max-h-[40px]  flex justify-between mt-[2%] mb-[16px]">
          <h2 className="text-lg-semibold  text-primary">Errors & issues</h2>
          <button
            onClick={() => navigate("/onboard/dashboard/errors")}
            className="max-w-[112px] max-h-[40px]  rounded-md border border-secondary py-[10px] px-[14px] bg-secondary text-sm-semibold text-primary"
          >
            View report
          </button>
        </div>

        <div className=" max-h-[400px] p-4  mb-[20px] overflow-auto">
          {errors?.length > 0 ? (
            errors?.map((item, index) => (
              <div
                key={index}
                className="bg-primary border-1 border-primary p-4 m-2 rounded-lg "
              >
                <div>
                  <ErrorOrWarningIcon color={true ? "#F04438" : "#F79009"} />
                </div>
                <p className="text-sm text-secondary">{item?.error}</p>
                <div className="flex mt-4 gap-2">
                  <h4
                    className="text-sm-semibold text-tertiary cursor-pointer"
                    onClick={() => handleDismiss(item?.id)}
                  >
                    Dismiss
                  </h4>
                </div>
              </div>
            ))
          ) : (
            <p className="text-primary  text-center">No errors found</p>
          )}
        </div>
      </div>
    </main>
  );
};

export default DashboardData;
