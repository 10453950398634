import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Modal,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllContexts,
  getPreCachedData,
  getPromptHistory,
} from "../../../../services/app/api";
import snackbar from "../../../../services/app/snackbar";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { useAuth } from "../../../Auth/AuthContext";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import MuiSelect from "../../../UI/MuiSelect";

const PromptSettings = () => {
  const dispatch = useDispatch();
  const { colors } = useThemeContext();
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const aiModelsList = useSelector(
    (state) => state.collectionsSlice.aiModelsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const { activeTeam } = useAuth();
  const [contextData, setContextData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openPreCacheModal, setOpenPreCacheModal] = useState(false);
  const [openVersionModal, setOpenVersionModal] = useState(false);
  const [promptId, setPromptId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [promptVersions, setPromptVersions] = useState([]);
  const [preCacheData, setPreCacheData] = useState(null);
  const [cacheCount, setCacheCount] = useState(1);
  const [maxTokenOutput, setMaxTokenOutput] = useState(0);
  const [switchChecked, setSwitchChecked] = useState(
    promptData?.cache_setting?.active || false
  );
  const [paginationData, setPaginationData] = useState({
    total: 0,
    pages: 1,
    currentPage: 0,
    hasPrev: false,
    hasNext: false,
    perPage: 10,
  });

  const handleProgressChange = (e, v) => {
    let data = {
      ...promptData,
      prompt_setting: {
        ...promptData?.prompt_setting,
        [e.target?.name]: v,
      },
    };
    dispatch(handleChangEditPrompt(data));
  };

  const handleVariationChange = (e, v) => {
    let data = {
      ...promptData,
      variationCount: v,
    };
    dispatch(handleChangEditPrompt(data));
  };

  useEffect(() => {
    if (!promptData?.llmModel || !promptData?.llmVersion) return;

    const matchedModel = aiModelsList.find(
      (model) => model.id === promptData.llmModel
    );
    if (!matchedModel) return;

    const matchedVersion = matchedModel.versions.find(
      (version) => version.id === promptData.llmVersion
    );
    if (!matchedVersion) return;
    setMaxTokenOutput(matchedVersion.max_output_tokens);
  }, [aiModelsList, promptData.llmModel, promptData.llmVersion]);

  useEffect(() => {
    let params = {
      page: 1,
      page_size: 500,
    };
    getAllContexts({ team_id: activeTeam, params }).then((res) =>
      setContextData(res.items)
    );
  }, [activeTeam]);

  const handleSwitchChange = (e) => {
    if (e.target.checked) {
      setOpenModal(true);
    } else {
      let data = {
        ...promptData,
        cache_setting: {
          ...promptData?.cache_setting,
          active: false,
          cache_count: 1,
        },
      };
      dispatch(handleChangEditPrompt(data));
      setSwitchChecked(false);
      setCacheCount(0);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSwitchChecked(false);
  };

  const handleSubmitCache = () => {
    let data = {
      ...promptData,
      cache_setting: {
        ...promptData?.cache_setting,
        active: true,
        cache_count: cacheCount,
      },
    };

    dispatch(handleChangEditPrompt(data));

    setOpenModal(false);
    setSwitchChecked(true);
  };

  useEffect(() => {
    if (promptData?.cache_setting?.active) {
      const cacheCountValue = promptData?.cache_setting?.cache_count ?? 1;
      setSwitchChecked(true);
      setCacheCount(cacheCountValue);
      if (promptData?.cache_setting?.cache_count === null) {
        const updatedPromptData = {
          ...promptData,
          cache_setting: {
            ...promptData.cache_setting,
            cache_count: cacheCountValue,
          },
        };

        if (
          updatedPromptData.cache_setting.cache_count !==
          promptData.cache_setting.cache_count
        ) {
          dispatch(handleChangEditPrompt(updatedPromptData));
        }
      }
    } else {
      setSwitchChecked(false);
      setCacheCount(0);

      const updatedPromptData = {
        ...promptData,
        cache_setting: {
          ...promptData.cache_setting,
          cache_count: 0,
        },
      };

      if (
        updatedPromptData.cache_setting.cache_count !==
        promptData.cache_setting.cache_count
      ) {
        dispatch(handleChangEditPrompt(updatedPromptData));
      }
    }
  }, [promptData]);

  const handleVersion = async () => {
    setLoading(true);
    setOpenVersionModal(true);
    try {
      const versionResponse = await getPromptHistory(promptData?.id);
      setPromptVersions(versionResponse);
      setLoading(false);
    } catch (error) {
      snackbar.error(error.error || "unable to fetch");
      setLoading(false);
    }
  };

  const parseCSV = (csvString) => {
    const rows = csvString.split("\n");
    const headers = rows[0].split(",").map((header) => header.trim());
    const data = rows.slice(1).map((row) => {
      const values = row.split(",").map((value) => value.trim());
      const rowObject = {};
      headers.forEach((header, index) => {
        rowObject[header] = values[index] || "";
      });
      return rowObject;
    });
    return data;
  };
  const handleFetchPreCacheData = async (id, page = 1) => {
    if (!promptData?.id) {
      snackbar.error("Prompt ID is missing!");
      return;
    }

    setLoading(true);

    setOpenPreCacheModal(true);

    try {
      const response = await getPreCachedData({
        prompt_id: id,
        params: { page },
      });

      let parsedDataWithTimestamp = [];

      for (let item of response.items) {
        const createdAtFormatted = item.items?.created_at
          ? new Date(item.items.created_at * 1000).toLocaleString()
          : "N/A";

        const expireTime = item?.expired_in;
        const expireTimeFormatted = expireTime
          ? convertSecondsToHM(expireTime)
          : "N/A";

        for (let responseItem of item.items?.response || []) {
          for (let data of responseItem.data || []) {
            let parsedData;

            try {
              if (promptData?.outputFormat === "json") {
                parsedData = Array.isArray(data)
                  ? data.map((item) => JSON.parse(item))
                  : JSON.parse(data);
              } else if (promptData?.outputFormat === "csv") {
                parsedData = parseCSV(data);
              } else if (promptData?.outputFormat === "xml") {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(data, "application/xml");
                parsedData = Array.from(
                  xmlDoc.getElementsByTagName("data")
                ).map((node) => ({
                  title: node.getElementsByTagName("title")[0]?.textContent,
                  description:
                    node.getElementsByTagName("description")[0]?.textContent,
                  tags: node.getElementsByTagName("tags")[0]?.textContent,
                }));
              }

              parsedDataWithTimestamp.push({
                "Created at": createdAtFormatted,
                "Expires in": expireTimeFormatted,
                "Otput data": parsedData,
              });
            } catch (parseError) {
              console.error("Parsing Error:", parseError);
            }
          }
        }
      }
      function convertSecondsToHM(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours} hr, ${minutes} min, ${remainingSeconds} sec`;
      }

      setPreCacheData(parsedDataWithTimestamp);
      setPaginationData({
        total: response.total,
        pages: Math.max(response.pages, 1),
        currentPage: response.page,
        hasPrev: response.has_prev,
        hasNext: response.has_next,
        perPage: response.per_page,
      });

      snackbar.success("Pre-cached data fetched successfully!");
    } catch (error) {
      setPreCacheData([]);
      snackbar.error(error.error || "Failed to fetch pre-cached data");
    } finally {
      setLoading(false);
    }
  };

  const renderDynamicData = (data) => {
    if (!data) return null;

    return (
      <div className="space-y-2">
        {typeof data === "string" ||
        typeof data === "number" ||
        typeof data === "boolean" ? (
          <Typography
            variant="body1"
            sx={{ paddingY: "8px", marginBottom: "8px" }}
          >
            {String(data)}
          </Typography>
        ) : Array.isArray(data) ? (
          <ul className="list-disc pl-2 space-y-2">
            {data.map((item, index) => (
              <li key={index}>
                <div className="text-gray-900 dark:text-gray-300">
                  {renderDynamicData(item)}
                </div>
              </li>
            ))}
          </ul>
        ) : typeof data === "object" ? (
          <div className="space-y-2">
            {Object.entries(data).map(([key, value]) => (
              <div
                key={key}
                className={`${
                  key === "Created at"
                    ? "border-t dark:border-gray-400 border-gray-800 pt-2"
                    : ""
                }`}
              >
                {key === "Otput data" ? (
                  <div className="space-y-1">
                    <strong className="dark:text-purple-700 text-green-600">
                      {key} :
                    </strong>
                    <div className="dark:text-purple-700 text-green-600">
                      {renderDynamicData(value)}
                    </div>
                  </div>
                ) : key === "Created at" || key === "Expires in" ? (
                  <div className="flex items-center gap-2">
                    <strong className="text-gray-900 dark:text-gray-300">
                      {key}:
                    </strong>
                    <div className="text-gray-900 dark:text-gray-300">
                      {renderDynamicData(value)}
                    </div>
                  </div>
                ) : (
                  <div className="space-y-0">
                    <strong className="text-gray-900 dark:text-gray-300">
                      {key} :
                    </strong>
                    <div className="text-gray-900 dark:text-gray-300">
                      {renderDynamicData(value)}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="w-full">
      <Stack
        gap={1}
        display={promptData?.model_type === "Text" ? "block" : "none"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"space-between"}
        >
          <FormLabel
            htmlFor="temperature"
            sx={{ color: colors.textTertiary, fontSize: 14 }}
          >
            Temperature:
          </FormLabel>
          <div className="w-[70%]">
            <Slider
              defaultValue={0.5}
              step={0.1}
              max={1}
              min={0}
              aria-label="Temperature"
              name="temperature"
              valueLabelDisplay="auto"
              onChange={handleProgressChange}
              value={promptData?.prompt_setting?.temperature}
            />
          </div>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"space-between"}
        >
          <FormLabel
            htmlFor="variation_count"
            sx={{ color: colors.textTertiary, fontSize: 14 }}
          >
            Variation:
          </FormLabel>
          <div className="w-[70%]">
            <Slider
              defaultValue={1}
              step={1}
              max={promptData?.llmModelName !== "anthropic" ? 8 : 1}
              min={1}
              aria-label="response"
              name="variation_count"
              valueLabelDisplay="auto"
              value={promptData?.variationCount}
              onChange={handleVariationChange}
            />
          </div>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"space-between"}
        >
          <FormLabel
            htmlFor="token"
            sx={{ color: colors.textTertiary, fontSize: 14 }}
          >
            Maximum output tokens to use:
          </FormLabel>
          <div className="w-[70%]">
            <Slider
              defaultValue={0}
              step={1}
              max={maxTokenOutput || 100000}
              min={0}
              aria-label="token"
              name="max_token"
              valueLabelDisplay="auto"
              value={promptData?.prompt_setting?.max_token || 0}
              onChange={handleProgressChange}
            />
          </div>
        </Box>
      </Stack>

      <div className="flex justify-between items-center gap-2">
        <FormControlLabel
          control={
            <Switch checked={switchChecked} onChange={handleSwitchChange} />
          }
          label={<span className="text-sm">Use cache</span>}
          sx={{ fontSize: 14, color: colors.textTertiary }}
        />
        {switchChecked && (
          <div className="flex items-center gap-2">
            <TextField
              label="Cache Count"
              type="text"
              value={cacheCount || ""}
              variant="outlined"
              size="small"
              sx={{ width: 100 }}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "" || /^[0-9]*$/.test(value)) {
                  const numValue = value === "" ? "" : Number(value);
                  if (numValue === "" || (numValue >= 1 && numValue <= 20)) {
                    setCacheCount(numValue);
                    let data = {
                      ...promptData,
                      cache_setting: {
                        ...promptData?.cache_setting,
                        cache_count: numValue,
                      },
                    };
                    dispatch(handleChangEditPrompt(data));
                  }
                }
              }}
              inputProps={{
                pattern: "[0-9]*",
              }}
            />
          </div>
        )}

        {switchChecked && (
          <div className="flex justify-end items-center gap-2">
            <FormLabel sx={{ color: colors.textTertiary, fontSize: 14 }}>
              Refresh cache every:
            </FormLabel>
            <div className="w-32">
              <MuiSelect
                value={promptData?.cache_setting?.interval}
                selectClassName="bg-primary"
                menuItems={[
                  { value: "day", label: "Day" },
                  { value: "week", label: "Week" },
                  { value: "month", label: "Month" },
                ]}
                onChange={(e) => {
                  let data = {
                    ...promptData,
                    cache_setting: {
                      ...promptData?.cache_setting,
                      interval: e.target.value,
                    },
                  };
                  dispatch(handleChangEditPrompt(data));
                }}
              />
            </div>
          </div>
        )}
      </div>

      <h1
        className="text-primary cursor-pointer hover:underline"
        onClick={handleVersion}
      >
        Show pre-cached data
      </h1>

      <Modal
        open={openVersionModal}
        onClose={() => setOpenVersionModal(false)}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            bgcolor: "background.paper",
            border: "1px solid gray",
            borderRadius: "8px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <Typography id="confirm-modal-description" sx={{ mt: 2 }}>
            These a the versions of your prompt , select one to check pre-cached
            data
          </Typography>

          <div className="mt-4 p-4">
            {loading ? (
              <CircularProgress />
            ) : (
              <div className="space-y-3">
                {promptVersions?.items?.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => {
                      setPromptId(item.id);
                      handleFetchPreCacheData(item.id, 1);
                    }}
                    className="w-full flex items-center justify-between p-3 border border-gray-300 rounded-lg shadow-md hover:bg-gray-100 dark:hover:bg-gray-800 transition"
                  >
                    <Typography variant="body1" className="text-lg font-medium">
                      {item.version}
                    </Typography>
                  </button>
                ))}
              </div>
            )}
          </div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpenVersionModal(false)}
            sx={{ mt: 2, ml: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            <h1 className="text-primary">Select Cache Count</h1>
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            How many cache results would you like to store?
          </Typography>
          <Slider
            value={cacheCount}
            onChange={(e, v) => setCacheCount(v)}
            step={1}
            min={2}
            max={20}
            valueLabelDisplay="auto"
          />
          <div className="flex justify-end mt-4">
            <Button onClick={handleSubmitCache} variant="contained">
              Submit
            </Button>
            <Button
              onClick={handleModalClose}
              variant="outlined"
              sx={{ ml: 1 }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openPreCacheModal}
        onClose={() => setOpenPreCacheModal(false)}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "400px",
            maxHeight: "80vh",
            bgcolor: "background.paper",
            border: "1px solid gray",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            overflowY: "auto",
          }}
        >
          <Typography id="precache-modal-title" variant="h6">
            <h1 className="text-primary">Pre-Cached Data</h1>
          </Typography>

          {loading ? (
            <div className="flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : preCacheData && preCacheData.length > 0 ? (
            <div className="mt-4 ">{renderDynamicData(preCacheData)}</div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No data available, please check if you have enabled cache in
              Advance settings and stored pre-cached data
            </Typography>
          )}
          {!loading && (
            <div className="flex justify-between mt-4">
              {(paginationData?.hasPrev || paginationData?.hasNext) && (
                <div className="flex justify-start">
                  <Button
                    onClick={() =>
                      handleFetchPreCacheData(
                        promptId,
                        paginationData.currentPage - 1
                      )
                    }
                    disabled={!paginationData.hasPrev}
                    variant="contained"
                  >
                    Previous
                  </Button>
                </div>
              )}

              <div className="flex justify-center items-center">
                <Typography variant="body2" color="textSecondary">
                  Page {paginationData.currentPage} of {paginationData.pages}
                </Typography>
              </div>

              {(paginationData?.hasPrev || paginationData?.hasNext) && (
                <div className="flex justify-end">
                  <Button
                    onClick={() =>
                      handleFetchPreCacheData(
                        promptId,
                        paginationData.currentPage + 1
                      )
                    }
                    disabled={!paginationData.hasNext}
                    variant="contained"
                  >
                    Next
                  </Button>
                </div>
              )}
            </div>
          )}

          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setOpenPreCacheModal(false)}
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "#d32f2f",
                },
              }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PromptSettings;
