import MuiMenu from "@Components/UI/MuiMenu";
import { ReactComponent as DraftDot } from "@Images/draftDot.svg";
import { ReactComponent as EditIcon } from "@Images/edit.svg";
import { ReactComponent as PublishDot } from "@Images/publishDot.svg";
import { Add as AddIcon, Remove } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import snackbarService from "@Services.App/snackbar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteCollection,
  DuplicateCollection,
  editCollection,
  getMyCollections,
} from "../../../services/app/api";
import snackbar from "../../../services/app/snackbar";
import { formatTimestampToDate } from "../../../services/generalFunctions";
import {
  handleLoading,
  handleResetEdit,
} from "../../../store/collectionsSlice";
import { useAuth } from "../../Auth/AuthContext";
import { useThemeContext } from "../../Themes/ThemeContextProvider";
import FilterField from "../../UI/FilterField";
import { ClockCountIcon, TrashIcon } from "../../UI/IconPack";
import MuiModal from "../../UI/MuiModal";
import MuiTable from "../../UI/MuiTable";
import CollectionVersions from "../common/CollectionVersions";
import AddCollection from "./AddCollection";
import "./Collection.scss";
const Collections = () => {
  const { colors } = useThemeContext();
  const [searchCollection, setSearchCollection] = useState("");
  const { activeTeam, isAdminTeam, id: userId } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allCollections, setAllCollections] = useState({});
  const [collectionWritingMode, setCollectionWritingMode] = useState("new");
  const [collectionToEdit, setCollectionToEdit] = useState({});
  const [openDeleteCollection, setOpenDeleteCollection] = useState(false);
  const [openCollectionVersions, setOpenCollectionVersions] = useState(false);
  const [selectedCollectionName, setSelectedCollectionName] = useState("");

  const handelOpenCollectionVersion = (id) => setOpenCollectionVersions(id);
  const handelCloseCollectionVersion = () => {
    setOpenCollectionVersions(false);
    setSelectedCollectionName("");
  };

  const callCollections = (params = {}) => {
    dispatch(handleLoading(true));
    if (!!searchCollection) {
      params["searchKey"] = searchCollection;
    }
    getMyCollections({
      team_id: activeTeam,
      params: { ...params, current: true, limit: 9 },
    })
      .then((res) => {
        setAllCollections(res);
      })
      .catch((error) => {
        snackbarService.error({
          title: `${(error.error && error.error.toString()) || "No Data"}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
      });
  };

  useEffect(() => {
    let debounceSearch = setTimeout(() => {
      callCollections({ page: 1 });
    }, 500);
    return () => clearTimeout(debounceSearch);
  }, [searchCollection, activeTeam]);

  const handleResetSearchCollection = () => {
    setSearchCollection("");
    callCollections({ page: 1 });
  };

  const handleRename = (collection) => {
    setCollectionToEdit(collection);
    setCollectionWritingMode("edit");
  };
  const handleRemove = () => {
    deleteCollection(openDeleteCollection)
      .then((res) => {
        callCollections();
      })
      .catch((error) => {
        snackbarService.error({
          title: `${(error.error && error.error.toString()) || "No Data"}`,
        });
      })
      .finally(() => {
        setOpenDeleteCollection(false);
      });
  };
  const handleDuplicate = (id) => {
    dispatch(handleLoading(true));

    DuplicateCollection({
      team_id: activeTeam,
      data: {
        collection_id: id,
      },
    })
      .then((response) => {
        snackbarService.success({
          title: `${"collection duplicated successfully"}`,
        });
        callCollections({ page: 1 });
      })
      .catch((error) => {
        snackbarService.error({
          title: `${(error.error && error.error.toString()) || "No Data"}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
      });
  };

  const handleUnbublish = () => {};
  const handleAddToTemplates = (collection) => {
    let payload = {
      data: {
        in_app_used: !collection?.in_app_used,
      },
    };
    payload["collection_Id"] = collection?.id;
    editCollection(payload)
      .then(() => {
        snackbar.success({
          title: collection?.in_app_used
            ? "Collection removed from templates"
            : "Collection Added to templates",
        });
        callCollections();
      })
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      });
  };

  const renderAction = (row) => {
    let menuListItems = [
      {
        icon: <EditIcon />,
        title: "Edit",
        onClick: () => handleRename(row),
      },
      {
        icon: <ClockCountIcon />,
        title: "Versions",
        onClick: () => {
          handelOpenCollectionVersion(row?.id);
          setSelectedCollectionName(row?.name);
        },
      },
      {
        icon: (
          <ContentCopyIcon className="text-primary" style={{ width: "16px" }} />
        ),
        title: <span className="text-primary ">Duplicate</span>,
        onClick: () => handleDuplicate(row?.id),
        colorRed: true,
      },
      {
        icon: <TrashIcon color={"#F97066"} />,
        title: <span className="text-textErrorPrimary">Remove</span>,
        onClick: () => setOpenDeleteCollection(row?.id),
        colorRed: true,
      },
      // {
      //   icon: <ArrowCounterClockwise />,
      //   title: "Unpublish",
      //   onClick: handleUnbublish,
      // },
    ];
    if (isAdminTeam === "yes") {
      menuListItems.splice(2, 0, {
        icon: row?.in_app_used ? (
          <Remove fontSize="small" />
        ) : (
          <AddIcon fontSize="small" />
        ),
        title: row?.in_app_used ? "Remove from Templates" : "Add to Templates",
        onClick: () => handleAddToTemplates(row),
      });
    }
    return <MuiMenu menuListItems={menuListItems} />;
  };

  const columns = [
    {
      field: "name",
      title: "Name",
      width: "40%",
      renderAction: (row) => (
        <span>
          <p className="text-sm-medium">{row.name}</p>
          <p className="text-sm text-tertiary">
            {row?.prompt_count || "0"} prompts
          </p>
        </span>
      ),
    },
    // { field: "description", title: "Description" },
    {
      style: "collection_versions",
      title: "Last published",

      renderAction: (row) => (
        <span>
          {" "}
          {row?.collection_versions?.length == 1 ? (
            <div className="border-2 w-fit rounded-lg px-[6px] py-[2px] flex items-center gap-1 border-primary">
              <DraftDot />
              Not published
            </div>
          ) : (
            <div className="border-2 w-fit rounded-lg px-[6px] py-[2px] flex items-center gap-1 border-primary">
              <PublishDot />
              {!!row?.collection_versions[0]?.updated_at
                ? formatTimestampToDate(
                    row?.collection_versions[0]?.updated_at,
                    "MMMM DD, YYYY"
                  )
                : "-"}
            </div>
          )}
        </span>
      ),
    },
    {
      field: "collection_versions",
      title: "Date created",
      renderAction: (row) => (
        <span className="text-tertiary">
          {formatTimestampToDate(row?.created_at, "MMMM DD, YYYY")}
        </span>
      ),
    },
    {
      field: "created_by",
      title: "Created by",
      renderAction: (row) => (
        <span className="text-tertiary">
          {" "}
          {userId == row?.created_by?.id ? "You" : row?.created_by?.first_name}
        </span>
      ),
    },
    {
      title: "Edit",
      renderAction: renderAction,
    },
  ];
  return (
    <div>
      <MuiModal
        open={!!openDeleteCollection}
        handleClose={() => setOpenDeleteCollection(false)}
        title={"Delete Collection"}
        message={
          "Are you sure you want to delete this collection? This action cannot be undone."
        }
        handleConfirm={handleRemove}
        showDeleteIcon={true}
      />

      <CollectionVersions
        open={!!openCollectionVersions}
        handleModal={handelCloseCollectionVersion}
        collectionId={openCollectionVersions}
        collectionName={selectedCollectionName}
      />

      {allCollections?.items?.length == 0 && searchCollection == "" ? (
        <AddCollection
          callCollections={callCollections}
          mode={collectionWritingMode}
          collection={collectionToEdit}
          searchCollection={searchCollection}
          allCollections={allCollections}
          onClose={() => {
            setCollectionWritingMode("new");
          }}
        />
      ) : (
        <div className={`py-10 px-28  border-t-[1px] border-primary `}>
          <div className="flex justify-between  px-2 py-4 items-center pb-2 mb-4">
            <h1 className="text-lg-semibold text-primary">Collections</h1>

            <div className="max-h-[44px] max-w-[602px] flex items-center gap-2">
              <FilterField
                placeholder="search collection"
                value={searchCollection}
                onChange={(e) => setSearchCollection(e.target.value)}
                onReset={handleResetSearchCollection}
              />
              <AddCollection
                callCollections={callCollections}
                mode={collectionWritingMode}
                collection={collectionToEdit}
                searchCollection={searchCollection}
                allCollections={allCollections}
                onClose={() => {
                  setCollectionWritingMode("new");
                }}
              />
            </div>
          </div>
          <MuiTable
            data={allCollections.items}
            columns={columns}
            page={allCollections?.page}
            pages={allCollections?.pages}
            onPageChange={(v, page) => callCollections({ page: page })}
            onSearchChange={(e) => setSearchCollection(e.target.value)}
            onSearchReset={handleResetSearchCollection}
            searchValue={searchCollection}
            tableTitle="Collections"
            onRowClick={(row) => {
              dispatch(handleResetEdit());
              navigate(`${row?.id}`, {
                state: { collection: row },
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Collections;
