import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";

export const PercentageChange = (currentValue, previousValue = 0) => {
  const percentageChange =
    previousValue > 0
      ? ((currentValue - previousValue) / previousValue) * 100
      : currentValue > 0
      ? 100
      : 0;

  const isPositive = percentageChange >= 0;

  return (
    <div
      className={`min-w-[70px] max-w-full h-[28px] ${
        isPositive
          ? "bg-green-200 dark:bg-green-950 border-green-500 dark:border-green-800"
          : "bg-red-200 dark:bg-red-950 border-red-500 dark:border-red-800"
      } border rounded-full flex justify-center items-center gap-1 px-2`}
    >
      {isPositive ? (
        <ArrowUpwardOutlinedIcon
          className=" text-green-500 text-center"
          style={{ fontSize: "12px" }}
        />
      ) : (
        <ArrowDownwardIcon
          className=" text-red-500 text-center"
          style={{ fontSize: "12px" }}
        />
      )}
      <h2
        className={`text-sm ${
          isPositive
            ? "text-green-700 dark:text-green-300"
            : "text-red-700 dark:text-red-300"
        }`}
      >
        {Math.abs(percentageChange.toFixed(1))}%
      </h2>
    </div>
  );
};
export const showFormated = (value) => {
  const isPositive = value >= 0;

  return (
    <div
      className={`min-w-[70px] max-w-full h-[28px] ${
        isPositive
          ? "bg-green-200 dark:bg-green-950 border-green-500 dark:border-green-800"
          : "bg-red-200 dark:bg-red-950 border-red-500 dark:border-red-800"
      } border rounded-full flex justify-center items-center gap-1 px-2`}
    >
      {isPositive ? (
        <ArrowUpwardOutlinedIcon
          className=" text-green-500 text-center"
          style={{ fontSize: "12px" }}
        />
      ) : (
        <ArrowDownwardIcon
          className=" text-red-500 text-center"
          style={{ fontSize: "12px" }}
        />
      )}
      <h2
        className={`text-sm ${
          isPositive
            ? "text-green-700 dark:text-green-300"
            : "text-red-700 dark:text-red-300"
        }`}
      >
        {Math.abs(value.toFixed(1))}%
      </h2>
    </div>
  );
};
